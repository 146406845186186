import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { FailReasons } from '../constants';
import {
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '../../../mappers/pricing-plan-helpers';
import { WidgetConfig } from '../../../types/shared-types';

export const handleNavigation = async ({
  config,
  wixCodeApi,
  isPreview,
  wixSdkAdapter,
  onNavigationFailed,
}: {
  config: WidgetConfig;
  wixCodeApi: IWixAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  isPreview: boolean;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
}) => {
  if (
    isPreview ||
    wixSdkAdapter.isDemoMode() ||
    wixSdkAdapter.isTemplateMode() ||
    (config.isFeatureEnabled &&
      (await isPricingPlanNavigateEnabled(wixSdkAdapter, config.serviceDto)))
  ) {
    const bookingsRelativeUrl = getBookingsRelativeUrl(config, wixCodeApi);
    wixCodeApi.location.to &&
      wixCodeApi.location.to(`${bookingsRelativeUrl}/book`);
  } else {
    if (!config.isFeatureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!(await wixSdkAdapter.isPricingPlanInstalled())) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan(config.serviceDto)) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (wixSdkAdapter, serviceDto) => {
  const isPricingPlanInstalled = await wixSdkAdapter.isPricingPlanInstalled();
  return (
    !isServiceOfferedAsPricingPlan(serviceDto, isPricingPlanInstalled) ||
    (isServiceConnectedToPricingPlan(serviceDto) && isPricingPlanInstalled)
  );
};

const getBookingsRelativeUrl = (config, wixCodeApi): string => {
  const fullBookingsUrl = config.serviceDto.fullUrl;
  const baseUrl = wixCodeApi.location.baseUrl;
  if (baseUrl) {
    const urlParts = fullBookingsUrl.split(baseUrl);
    return urlParts.length > 1 ? urlParts[1] : fullBookingsUrl;
  }
  return fullBookingsUrl;
};
